import React from "react";
import {
    EntryTakeScheduledMedicationsItem,
    EntryTakeUnscheduledMedicationsItem,
    TakeScheduledMedicationsEntry,
} from "../../types/Entry";
import {dateObjectFromString, formatFriendlyDate} from "../../utils/DateTime";
import {useQuery} from "@tanstack/react-query";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../api/medication-options";

export const EntryTakenMedicationItem = (props: {
    record: TakeScheduledMedicationsEntry,
    entry: EntryTakeUnscheduledMedicationsItem,
}) => {
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);

    return <li>
        <div className="item in" style={{display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1}}>
                <div className={"pe-1"} style={{width: "5rem", textAlign: "right"}}>
                    {props.entry.strength} {optionSingularToPlural(lookups?.units, props.entry.strength, props.entry.unit)}
                </div>
                <div style={{flexGrow: 1}}>
                    {props.entry.name}
                </div>
                {props.entry.time_taken && <div className="text-center" style={{width: "4rem"}}>
                    {props.entry.time_taken}
                    {props.entry.date_taken !== props.record.date &&
                        " (" + formatFriendlyDate(dateObjectFromString(props.entry.date_taken)) + ")"
                    }
                </div>}
        </div>
    </li>;
}

export const EntrySkippedMedicationItem = (props: {
    entry: EntryTakeScheduledMedicationsItem,
}) => {
    return <li style={{display: "block"}}>
        <div className="item">
            <div className="in" style={{display: "flex", alignItems: "center"}}>
                <div style={{flexGrow: 1, margin: "auto 0 auto 0"}}>
                    {props.entry.name}
                </div>
            </div>
        </div>
    </li>;
}
