import React from "react";
import {TakeScheduledMedicationsEntry, TakeUnscheduledMedicationsEntry} from "../../types/Entry";
import {fetchMedicationOptionsQueryOptions, optionSingularToPlural} from "../../api/medication-options";
import {useQuery} from "@tanstack/react-query";
import {NavLink} from "react-router-dom";
import {PillIcon} from "../../components/Icons/Icons";
import {IonIcon} from "@ionic/react";
import {flashOutline} from "ionicons/icons";
import {dateObjectFromString, formatReallyLongDate} from "../../utils/DateTime";
import {EntryTakenMedicationItem} from "./EntryItems";

export default function EntryTakeUnscheduledMedications(props: {
    variant: string,
    id: string,
    date: string,
    time: string,
    data: TakeUnscheduledMedicationsEntry,
}) {
    switch (props.variant) {
        case "full":
            return <EntryTakeUnscheduledMedicationsFull id={props.id} date={props.date} time={props.time} data={props.data}/>;
        case "list-item":
        default:
            return <EntryTakeUnscheduledMedicationsListItem id={props.id} time={props.time} data={props.data}/>;
    }
}

function summary(data: TakeUnscheduledMedicationsEntry, units?: Map<string, string>): JSX.Element {
    if (data.items.length === 0) {
        return <>No medications in this entry (this should not happen!)</>;
    }

    let parts = [];
    for (let i = 0; i < data.items.length; i++) {
        let item = data.items[i];
        parts.push(item.strength + " " + (units ? optionSingularToPlural(units, item.strength, item.unit) : item.unit) + " of " + item.name);
    }

    return <>{parts.map(((item, idx) => <div key={idx}>{item}</div>))}</>;
}

function EntryTakeUnscheduledMedicationsListItem(props: {
    id: string,
    time: string,
    data: TakeScheduledMedicationsEntry,
}) {
    const {data: lookups} = useQuery(fetchMedicationOptionsQueryOptions);

    return <li>
        <NavLink to={"/entries/" + props.id} className="item">
            <div className="in" style={{display: "flex"}}>
                <div className={"text-secondary text-center me-1"} style={{width: "2.75rem"}}>{props.time}</div>
                <div className={"me-1"}
                     style={{
                         marginTop: "2px",
                         width: "2rem",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "baseline"
                     }}>
                    <img src={require("../../components/Icons/assets/pill-duotone.svg").default} alt={"medication"}
                         style={{width: "1rem", height: "1rem", marginTop: "-2px"}}/>
                    <IonIcon icon={flashOutline} style={{fontSize: "1rem"}}/>
                </div>
                <div style={{flexGrow: 1}}>{summary(props.data, lookups ? lookups.units : undefined)}</div>
            </div>
        </NavLink>
    </li>;
}

function EntryTakeUnscheduledMedicationsFull(props: {
    id: string,
    date: string,
    time: string,
    data: TakeUnscheduledMedicationsEntry,
}) {
    const {date, time, data} = props;

    if (data.items.length === 0) {
        return <></>;
    }

    return (<>
        <div className="section mt-2 mb-2">
            <div className="listed-detail mt-3 text-center">
                <img src={PillIcon} alt={"pill"} style={{width: "40px", height: "40px"}}/>
                <h3 className="text-center mt-2">
                    <time>
                        {formatReallyLongDate(dateObjectFromString(date))}<br/>
                        {time}
                    </time>
                </h3>
            </div>
        </div>
        <div className="section full mt-4">
            {data.items.length > 0 && <div className={"mb-2"}>
                <div className="section-title bg-primary text-light rounded-top"
                     style={{display: "flex", alignItems: "center"}}>
                    <div style={{flexGrow: 1}}>Medication{data.items.length === 1 ? "" : "s"} taken</div>
                </div>
                <ul className="listview simple-listview rounded-bottom">
                    {data.items.map(dose =>
                        <EntryTakenMedicationItem key={dose.medication_id} record={data}
                                                  entry={dose}/>)}
                </ul>
            </div>}
            <div className={"mb-2"}>
                <div className="section-title bg-secondary text-light rounded-top"
                     style={{display: "flex", alignItems: "center"}}>
                    <div className={"flex-grow-1"}>Notes</div>
                </div>
                <div className={"bg-light p-2 rounded-bottom" + (data.notes === "" ? " text-muted" : " text-dark")}
                     style={{whiteSpace: "pre-line", lineHeight: "1.1rem"}}>
                    {data.notes
                        ?
                        <p className="card-text">{data.notes ?? ""}</p>
                        :
                        <p className="card-text" style={{fontStyle: "italic"}}>No notes.</p>
                    }
                </div>
            </div>
        </div>
    </>);
}
