import React, {useEffect} from "react";
import {EntryTakeScheduledMedicationsItem, TakeScheduledMedicationsEntry,} from "../../types/Entry";
import {optionSingularToPlural} from "../../api/medication-options";
import {Link, NavLink} from "react-router-dom";
import {PillIcon} from "../../components/Icons/Icons";
import {IonIcon} from "@ionic/react";
import {timeOutline} from "ionicons/icons";
import {dateObjectFromString, formatReallyLongDate} from "../../utils/DateTime";
import {ScheduledDoseStatusSkipped, ScheduledDoseStatusTaken} from "../../types/MedicationSchedules";
import {EntrySkippedMedicationItem, EntryTakenMedicationItem} from "./EntryItems";

interface MedsForDisplay {
    taken: Map<string, EntryTakeScheduledMedicationsItem[]>;
    skipped: EntryTakeScheduledMedicationsItem[];
    total: number;
}

export default function EntryTakeScheduledMedications(props: {
    variant: string,
    id: string,
    time: string,
    data: TakeScheduledMedicationsEntry,
}) {
    switch (props.variant) {
        case "full":
            return <EntryTakeScheduledMedicationsFull id={props.id} time={props.time} data={props.data}/>;
        case "list-item":
        default:
            return <EntryTakeScheduledMedicationsListItem id={props.id} time={props.time} data={props.data}/>;
    }
}

function summary(data: TakeScheduledMedicationsEntry, units?: Map<string, string>): JSX.Element {
    if (data.taken_count === 0 && data.skipped_count === 0) {
        return <>No medications in this entry (this should never happen!)</>;
    }

    let byTime = new Map<string, JSX.Element[]>();
    for (const item of data.items as EntryTakeScheduledMedicationsItem[]) {
        let key = item.time_taken;
        if (item.status === "skipped") {
            key = "skipped";
        }

        let meds = byTime.get(key);
        if (meds === undefined) {
            meds = [];
            byTime.set(key, meds);
        }

        meds.push(<>{item.strength + " " + (units ? optionSingularToPlural(units, item.strength, item.unit) : item.unit) + " of " + item.name}</>);
    }

    return <div style={{display: "table"}}>
        {Array.from(byTime.entries()).map(([time, meds], idx) => {
            return <div key={idx} style={{display: "table-row"}}>
                <div style={{display: "table-cell", textAlign: "right"}} className={"text-secondary pe-1"}>
                    <time>{time}</time>
                </div>
                <div
                    style={{display: "table-cell"}}>{meds.length} medication{meds.length === 1 ? "" : "s"}{time === "skipped" ? "" : " taken"}</div>
            </div>;
        })}
    </div>;
}

function EntryTakeScheduledMedicationsListItem(props: {
    id: string,
    time: string,
    data: TakeScheduledMedicationsEntry,
}) {
    return <li>
        <NavLink to={"/entries/" + props.id} className="item">
            <div className="in" style={{display: "flex", justifyContent: "center", alignItems: "flex-start"}}>
                <div className={"text-secondary text-center me-1"} style={{width: "2.75rem"}}>{props.time}</div>
                <div className={"me-1"}
                     style={{
                         marginTop: "2px",
                         width: "2rem",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "baseline"
                     }}>
                    <img src={require("../../components/Icons/assets/pill-duotone.svg").default} alt={"medication"}
                         style={{width: "1rem", height: "1rem", marginTop: "-2px"}}/>
                    <IonIcon icon={timeOutline} style={{fontSize: "1rem"}}/>
                </div>
                <div style={{flexGrow: 1}}>
                    {summary(props.data)}
                </div>
            </div>
        </NavLink>
    </li>;
}

function EntryTakeScheduledMedicationsFull(props: {
    id: string,
    time: string,
    data: TakeScheduledMedicationsEntry
}) {
    const {data} = props;
    const [takenDoses, setTakenDoses] = React.useState<EntryTakeScheduledMedicationsItem[]>([]);
    const [skippedDoses, setSkippedDoses] = React.useState<EntryTakeScheduledMedicationsItem[]>([]);

    useEffect(() => {
        if (data.items) {
            setTakenDoses(data.items.filter(item => item.status === ScheduledDoseStatusTaken));
            setSkippedDoses(data.items.filter(item => item.status === ScheduledDoseStatusSkipped));
        }
    }, [data]);

    let firstTime = "";
    let displayItems: MedsForDisplay = {
        taken: new Map<string, EntryTakeScheduledMedicationsItem[]>(),
        skipped: [],
        total: 0
    };
    for (const item of data.items as EntryTakeScheduledMedicationsItem[]) {
        if (item.status === "skipped") {
            displayItems.skipped.push(item);
            displayItems.total++;
            continue
        }

        if (item.status !== "taken") {
            console.error("Unexpected entry med status: [" + item.medication_id + "] " + item.status);
        }

        let meds = displayItems.taken.get(item.time_taken);
        if (meds === undefined) {
            meds = [];
            displayItems.taken.set(item.time_taken, meds);
            if (firstTime === "") {
                firstTime = item.time_taken;
            }
        }

        meds.push(item);
        displayItems.total++;
    }

    return (<>
        <div className="section mt-2 mb-2">
            <div className="listed-detail mt-3 text-center">
                <img src={PillIcon} alt={"pill"} style={{width: "40px", height: "40px"}}/>
                <h3 className="text-center mt-2">
                    <time>
                        {formatReallyLongDate(dateObjectFromString(data.date))}<br/>
                        {data.time}
                    </time>
                </h3>
            </div>
        </div>
        <div className="section full mt-4">
            {data.pending_count > 0 && <div className="card text-center mb-2">
                <h5 className="card-header bg-danger text-light text-center" style={{display: "block"}}>
                    There {data.pending_count === 1 ? "is" : "are"} {data.pending_count} dose{data.pending_count === 1 ? "" : "s"} yet
                    to be taken
                </h5>
                <div className="card-body">
                    <p className="card-text" style={{textWrap: "balance"}}>
                        Pending doses are automatically marked as <span className={"text-warning"}>skipped</span> after
                        24 hours
                    </p>
                    <Link to={"/medications/" + data.date + "/" + data.time} className="btn btn-primary">Take
                        medications &gt;</Link>
                </div>
            </div>}
            {takenDoses.length > 0 && <div className={"mb-2"}>
                <div className="section-title bg-primary text-light rounded-top"
                     style={{display: "flex", alignItems: "center"}}>
                    <div style={{flexGrow: 1}}>Medication{takenDoses.length === 1 ? "" : "s"} taken</div>
                    <div className={"text-center"} style={{width: "4rem", fontSize: "0.8rem"}}>Time</div>
                </div>
                <ul className="listview simple-listview rounded-bottom">
                    {takenDoses.map(dose =>
                        <EntryTakenMedicationItem key={dose.medication_id} record={data}
                                                  entry={dose}/>)}
                </ul>
            </div>}
            {skippedDoses.length > 0 && <div className={"mb-2"}>
                <div className="section-title bg-warning text-dark rounded-top"
                     style={{display: "flex", alignItems: "center"}}>
                    <div style={{flexGrow: 1}}>Medication{skippedDoses.length === 1 ? "" : "s"} skipped</div>
                </div>
                <ul className="listview simple-listview rounded-bottom">
                    {skippedDoses.map(dose =>
                        <EntrySkippedMedicationItem key={dose.medication_id} entry={dose}/>)}
                </ul>
            </div>}
            <div className={"mb-2"}>
                <div className="section-title bg-secondary text-light rounded-top"
                     style={{display: "flex", alignItems: "center"}}>
                    <div className={"flex-grow-1"}>Notes</div>
                </div>
                <div className={"bg-light p-2 rounded-bottom" + (data.notes === "" ? " text-muted" : " text-dark")}
                     style={{whiteSpace: "pre-line", lineHeight: "1.1rem"}}>
                    {data.notes
                        ?
                        <p className="card-text">{data.notes ?? ""}</p>
                        :
                        <p className="card-text" style={{fontStyle: "italic"}}>No notes.</p>
                    }
                </div>
            </div>
        </div>
    </>);

    // return (
    //     <div className="card">
    //         <div className="card-body ps-0 pe-0 pb-0">
    //             <div className="ms-2 me-2" style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
    //                 <img src={PillIcon} alt={"pill"} style={{width: "40px", height: "40px"}}/>
    //                 <div style={{flexGrow: 1}}>
    //                     <h2 className="card-title">Scheduled
    //                         medication{displayItems.total > 1 ? "s" : ""} taken</h2>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
}
